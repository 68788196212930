<template>
  <div class="main">

    <full-page-scroll v-model="currentPage" ref="fullPage">
      <div class="banner-item" v-for="(banner,i) in banners">

        <img :src="banner.img" :alt="$t(`headerMenu.customPlatform[${i+1}]`)"
             :title="$t(`headerMenu.customPlatform[${i+1}]`)"
             @click="handleBannerClick(banner)">
      </div>
    </full-page-scroll>
<!-- 
    <div class="titles">
      <transition name="fade-in-out" mode="out-in">
        <div class="title" v-if="index+1 == currentPage" v-for="(banner,index) in banners" :key="`title-${index}`">
          {{ $t(`headerMenu.customPlatform[${index + 1}]`) }}
        </div>
      </transition>
    </div>
 -->
    <div class="actions">
      <div class="action black" @click="goodsNormal">{{ $t("main.actions.normal") }}</div>
      <div class="action" @click="handleCustomClick">{{ $t("main.actions.custom") }}</div>
      <div class="action" @click="go_chat">{{ $t("main.actions.creative") }}</div>
    </div>

    <transition name="fade-in-out">
      <i class="el-icon-arrow-down arrow" @click="next" v-if="currentPage != banners.length"></i>
    </transition>
	
	
	
	<div class="sideFix">
		<div style="display: flex;flex-direction: column;">
			
			
		<el-tooltip effect="dark" :content="mobile" placement="left" transition="el-zoom-in-center">
		   <i class="el-icon-phone icon" style="font-size: 30px;"></i>
		</el-tooltip>
			   
		
		<!-- 	<el-tooltip effect="dark" content="Left Center 提示文字" placement="left" transition="el-zoom-in-center">
			     <i class="el-icon-user-solid icon"></i>
			</el-tooltip>
	 -->
	 <img src="../../assets/image/cat.png" style="width: 30px;cursor: pointer;"  @click="go_chat1" />
	 
			      <!-- <i class="el-icon-delete  icon" ></i> -->
			
		</div>
	</div>
	

  </div>
</template>

<script>

import FullPageScroll from "@/components/FullPageScroll";
import {getBanner} from "@/api/common";

export default {
  components: {FullPageScroll},
  data() {
    return {
      banners: [],
      currentPage: 1,
	  mobile:""
    }
  },
  computed: {
    currentBanner() {
      return this.banners[this.currentPage - 1];
    }
  },
  mounted() {
	  
	  this.$req.get("/client/im/common/config", {
	  	
	  }).then(async res => {
	   this.mobile=res.contact1;
	  })
	  
    getBanner().then(res => {
      this.banners = res
    })
	
  },
  methods: {
	  go_chat(){
		this.$router.push("/chat/index?iskefu=false")  
	  },
	  go_chat1(){
	  		this.$router.push("/chat/index?iskefu=true")  
	  },
	  goodsNormal(){
		  var currentPage=this.currentPage;
		this.$router.push({
			path:'/mall',
			query:{
				type:2,
				detailType:currentPage==1?'201':currentPage==2?'301':currentPage==3?'401':currentPage==4?'501':'',
			}
		})  
	  },
    next() {
      this.$refs.fullPage.next();
    },
    handleBannerClick(banner) {
      let {path} = banner;
      if (path) {
        this.$router.push({path})
      }
    },
    handleCustomClick() {
		
		
      let banner = this.currentBanner;
	  
	  if(banner.parm==2 || banner.parm==3){
		  this.$notify.info({
				title: this.$t('inviteCode.mesAlertTitle'),
				message:   this.$t('newadd.t.功能开发中'),
				duration: 2000
		  });
		  return
	  }
	  
      this.$router.push({path: '/custom', query: {type: banner.parm}})
    },
  },
}
</script>

<style scoped lang='scss'>
  
.main {
  height: 100vh;
  overflow-y: hidden;
}

.fullPageContainer {
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
}

.banner-item {
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    user-select: none;
    cursor: pointer;
  }
}

.titles {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%,0);
  font-size: 36px;
  color: #5f5f5f;
  text-align: center;


}

.actions {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 118px;
  justify-content: center;
  align-items: center;

  .action {
    font-size: 14px;
    background-color: rgba(255, 255, 255, .8);
    color: black;
    border-radius: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    margin: 0 50px;
    cursor: pointer;
    user-select: none;

    &.black {
      background-color: rgba(0, 0, 0, .6);
      color: white;
    }
  }
}

.arrow {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 38px 0;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}


.sideFix{
	position: fixed;
	right: 100px;
	bottom: 100px;
	z-index: 999;
	
	.icon{
	margin-bottom: 20px;
	font-size: 20px;
	}
}
</style>